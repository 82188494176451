import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { SUPPORT_PATHNAME } from '@glass/common/modules/pages/paths';
import {
  SUPPORT_EMAIL,
  SUPPORT_HOURS,
  SUPPORT_PHONE,
  SUPPORT_PHONE_FORMATTED,
} from '@glass/common/modules/support/rock';
import Grid from '@glass/web/components/base/Unstable_Grid2';
import ChatRounded from '@glass/web/components/base/icons/ChatRounded';
import EmailRounded from '@glass/web/components/base/icons/EmailRounded';
import HelpRounded from '@glass/web/components/base/icons/HelpRounded';
import PhoneRounded from '@glass/web/components/base/icons/PhoneRounded';
import { SUPPORT_SECTION_ID } from '@glass/web/modules/content/pages/contentIds';

import Link from '@glass/shared/components/Link/Link';
import Section from '@glass/shared/components/Section/Section';
import SectionButtonCta from '@glass/shared/components/Section/SectionButtonCta';
import SectionSpacer, { BOTTOM_EDGE } from '@glass/shared/components/Section/SectionSpacer';
import SubSection from '@glass/shared/components/Section/SubSection';
import withTrackingContext from '@glass/shared/modules/tracking/withTrackingContext';
import zendeskClient from '@glass/shared/modules/zendesk/zendeskClient';

const handleContactUs = () => zendeskClient('webWidget', 'open');

const SupportSection = React.forwardRef(
  (
    { isSupportPhone, onBeforeContact, title, subtitle, isAlternatingColor, type, className },
    ref,
  ) => {
    const { pathname } = useRouter();
    const handleContactClick = useCallback(
      (evt) => {
        evt.preventDefault();
        if (typeof onBeforeContact === 'function') {
          onBeforeContact();
        }
        handleContactUs();
      },
      [onBeforeContact],
    );

    const sm = isSupportPhone ? 6 : 4;
    const md = isSupportPhone ? 3 : 4;

    return (
      <Section
        ref={ref}
        disableCta
        className={className}
        isAlternatingColor={isAlternatingColor}
        subtitle={subtitle}
        title={title}
        type={type}
      >
        <Grid container spacing={1}>
          <Grid md={md} sm={sm} xs={12}>
            <SubSection
              icon={HelpRounded}
              subtitle={<Link href={SUPPORT_PATHNAME}>Frequently Asked Questions</Link>}
              title="FAQ"
            />
          </Grid>
          <Grid md={md} sm={sm} xs={12}>
            <SubSection
              icon={ChatRounded}
              subtitle={
                <Link href={SUPPORT_PATHNAME} onClick={handleContactClick}>
                  Chat with us
                </Link>
              }
              title="Chat"
            />
          </Grid>
          <Grid md={md} sm={sm} xs={12}>
            <SubSection
              icon={EmailRounded}
              subtitle={<Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>}
              title="Email"
            />
          </Grid>
          {isSupportPhone ? (
            <Grid md={md} sm={sm} xs={12}>
              <SubSection
                icon={PhoneRounded}
                subtitle={<Link href={`tel:${SUPPORT_PHONE}`}>{SUPPORT_PHONE_FORMATTED}</Link>}
                title="Phone"
              />
            </Grid>
          ) : null}
        </Grid>
        {pathname !== SUPPORT_PATHNAME ? (
          <SectionSpacer edge={BOTTOM_EDGE}>
            <SectionButtonCta component={Link} href={SUPPORT_PATHNAME}>
              Contact us
            </SectionButtonCta>
          </SectionSpacer>
        ) : null}
      </Section>
    );
  },
);

SupportSection.propTypes = {
  className: PropTypes.string,
  isAlternatingColor: PropTypes.bool,
  isSupportPhone: PropTypes.bool,
  onBeforeContact: PropTypes.func,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};

SupportSection.defaultProps = {
  title: 'Need help?',
  subtitle: `We love hearing from you. We're available ${SUPPORT_HOURS}. Send us a message and we'll get back to you as soon as possible.`,
  isSupportPhone: false,
  type: SUPPORT_SECTION_ID,
};

export default React.memo(withTrackingContext(SupportSection, SUPPORT_SECTION_ID));
